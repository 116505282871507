<template>
    <metainfo>
        <template v-slot:title="{ content }">
            {{ content ? `${content} | BuyBusinessClassTickets` : 'BuyBusinessClassTickets - Business Class Flights' }}
        </template>
    </metainfo>
    <sticky-contact-button />
    <burgher-menu />
    <modal-status />
    <quote-loader :loading="loading" :appWidth="appWidth" />
    <router-view />
    <footer-component />
    <coupon-form ref="couponForm" />
</template>

<script>
// Components
import FooterComponent from './components/Footer/FooterComponent.vue'
import BurgherMenu from './components/BurgherMenu.vue'
import ModalStatus from './common/ModalStatus.vue'
import QuoteLoader from '@/common/QuoteLoader.vue'
import StickyContactButton from './common/UI/Buttons/StickyContactButton.vue'
import CouponForm from './components/PopUp/CouponForm.vue'

// Vuex
import { mapState } from 'vuex'

export default {
    name: 'App',
    components: {
        FooterComponent,
        BurgherMenu,
        ModalStatus,
        QuoteLoader,
        StickyContactButton,
        CouponForm,
    },
    computed: {
        ...mapState({
            appWidth: (state) => state.app.appWidth,
            loading: (state) => state.app.quoteLoading,
        }),
    },

    methods: {
        onResize() {
            this.$store.commit('changeAppWidth', window.innerWidth)
        },
        showCouponForm() {
            this.$refs.couponForm.openModal()
        },
    },
    beforeCreate() {
        this.$store.dispatch('getSettings')
    },
    created() {
        this.onResize()
        window.addEventListener('resize', this.onResize)
        setTimeout(() => {
            this.showCouponForm()
        }, 5000)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    },
    watch: {
        appWidth(value) {
            if (value > 1200) {
                this.$store.commit('displayBurgherMenu', false)
            }
        },
    },
}
</script>

<style lang="scss" src="@/styles/general.scss"></style>
