<template>
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
            <button class="close-button" @click="closeModal">&times;</button>
            <div class="modal-columns">
                <!-- Coloană Imagine -->
                <div class="modal-image">
                    <img src="https://i.imgur.com/jtViKEn.jpeg" alt="Airline Discount" />
                </div>

                <div class="modal-form">
                    <h2>Save Extra $100 Now</h2>
                    <p>
                        Please provide your phone number and share your promo code<br />
                        with your agent to receive an additional discount
                    </p>
                    <p class="promo-code">Your Code: <span class="promo-code-value">SAVE100</span></p>
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <input type="text" id="name" v-model="name" placeholder="Name" :error="formErrors.name" />
                        </div>
                        <div class="form-group">
                            <input
                                type="email"
                                id="email"
                                v-model="email"
                                placeholder="Email"
                                :error="formErrors.email"
                            />
                        </div>
                        <div class="form-group">
                            <vue-tel-input
                                v-model="phone"
                                mode="international"
                                :dynamicPlaceholder="true"
                                :validCharactersOnly="true"
                                :defaultCountry="'US'"
                                :inputOptions="{ showDialCode: true, maxlength, name: 'phone' }"
                                :class="{ valid, 'not-valid': !valid, 'has-error': formErrors.phone }"
                                @validate="telControl"
                            />
                        </div>
                        <div class="form-group">
                            <label class="terms">
                                <input type="checkbox" v-model="agreeToTerms" required />
                                I agree to the <b>Terms and Conditions</b> and <b>Privacy Policy.</b>
                            </label>
                        </div>

                        <button type="submit" class="submit-button" :disabled="!canSubmit">CLAIM DISCOUNT</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import { telInputControl } from '@/helpers/functions'
import { sendCouponLead } from '@/controllers/flyController'

export default {
    components: {
        VueTelInput,
    },
    data() {
        return {
            showModal: false,
            name: '',
            email: '',
            phone: null,
            maxlength: 25,
            valid: null,
            agreeToTerms: false,
            formErrors: {
                name: null,
                phone: null,
                email: null,
            },
            coupon: 'SAVE100',
        }
    },
    computed: {
        canSubmit() {
            return this.name.trim() !== '' && this.email.trim() !== '' && this.phone !== null && this.agreeToTerms
        },
    },
    methods: {
        openModal() {
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        async handleSubmit() {
            this.formErrors.name = null
            this.formErrors.phone = null
            this.formErrors.email = null

            await this.sendCouponLead()
        },

        async sendCouponLead() {
            const client = {
                name: this.name,
                email: this.email,
                phone: this.phone?.replace(/ /g, ''),
            }

            if (!this.agreeToTerms) {
                this.$store.commit('showModal', {
                    display: true,
                    status: 'error',
                    title: 'Validation error',
                    body: 'You must agree to the terms and conditions.',
                })
                return
            }

            const [result, error] = await sendCouponLead({
                client,
                coupon: this.coupon,
            })

            if (error?.type === 'validation') {
                this.formErrors[error.input] = true

                this.$store.commit('showModal', {
                    display: true,
                    status: 'error',
                    title: 'Validation error',
                    body: error.message,
                })
                return
            }

            if (error !== null) {
                this.$store.commit('showModal', {
                    display: true,
                    status: 'error',
                    body: 'Something went wrong',
                    title: 'Request failed',
                })
                return
            }

            if (result?.data?.success) {
                this.$store.commit('showModal', {
                    display: true,
                    status: 'success',
                    title: 'Request Successful',
                    body: 'Your request was submitted successfully.',
                })

                this.closeModal()
            }
        },

        telControl(validation) {
            telInputControl(validation, this)
            this.formErrors.phone = validation.valid === false
        },
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #faebd7;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
}

.modal-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-image {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.modal-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
    max-height: 400px;
}

/* .modal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
} */

.modal-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

h2 {
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

p {
    margin-bottom: 20px;
    color: #777;
    text-align: center;
    font-size: 12px;
    font-family: Arial;
    margin-left: -20px;
}

.form-group {
    margin-bottom: 15px;
}

input[type='text'],
input[type='tel'],
input[type='email'] {
    border: solid 1px gray;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
}
input[type='text'],
input[type='tel']:focus,
input[type='email']:focus {
    outline: none;
    border-color: #a8b6c5;
}

.separator {
    display: block;
    text-align: center;
    margin: 10px 0;
    color: #555;
}
.separator-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.separator-line {
    flex: 1;
    border: 0;
    border-top: 1px solid #555;
    margin: 0 10px;
}
.promo-code {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.promo-code-value {
    display: inline-block;
    padding: 5px 10px;
    border: 2px dotted #333;
    background-color: #fff;
    font-weight: bold;
    color: #b88454;
}

.separator-text {
    color: #555;
    font-size: 14px;
    white-space: nowrap;
}
.terms {
    font-size: 13px;
    color: #555;
    font-weight: 500;
}

.submit-button {
    background-color: #d4a373;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    width: auto;
    max-width: 100%;
    display: inline-block;
}

.submit-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
    background-color: #b88454;
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        padding: 10px;
        max-width: 100%;
        flex-direction: column;
    }

    .modal-columns {
        flex-direction: column;
    }

    .modal-image {
        padding-right: 0;
        margin-bottom: 10px;
    }

    .modal-image img {
        max-height: 200px;
    }

    .modal-form {
        padding: 10px;
    }

    .close-button {
        font-size: 1.2rem;
        top: 5px;
        right: 5px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .submit-button {
        padding: 8px 12px;
    }
}
.vue-tel-input input::placeholder {
    color: #888;
    opacity: 1;
}

.vue-tel-input {
    background-color: white;
}
</style>
