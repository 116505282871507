<template>
    <div ref="header" class="header-container">
        <div class="trust-container">
            <div class="logo">
                <router-link aria-label="Go to main page" class="logo-link" to="/">
                    <img :src="require('@/assets/images/static/bct-logo.png')" width="150" height="89" alt="Logo" />
                </router-link>
                <div @click="burgherMenu" class="burgher">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.66663 10.6667C2.66663 10.2985 2.9651 10 3.33329 10H12.6666C13.0348 10 13.3333 10.2985 13.3333 10.6667C13.3333 11.0349 13.0348 11.3333 12.6666 11.3333H3.33329C2.9651 11.3333 2.66663 11.0349 2.66663 10.6667Z"
                            fill="#111315"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.66663 5.33268C2.66663 4.96449 2.9651 4.66602 3.33329 4.66602H12.6666C13.0348 4.66602 13.3333 4.96449 13.3333 5.33268C13.3333 5.70087 13.0348 5.99935 12.6666 5.99935H3.33329C2.9651 5.99935 2.66663 5.70087 2.66663 5.33268Z"
                            fill="#111315"
                        />
                    </svg>
                </div>
                <dialog-form />
            </div>
            <links-container>
                <router-link class="page-link" to="/" title="Home">Home</router-link>
                <router-link class="page-link" to="/about-us" title="Airlines">About Us</router-link>
                <router-link class="page-link" to="/airlines" title="Airlines">Airlines</router-link>
                <a href="https://blog.businessclass-tickets.com/" class="page-link" title="About Us">Blog</a>

                <!--        <router-link
          :class="`page-link ${
            $route.path.match(/blog\/\d+/) !== null ? 'router-link-active' : ''
          }`"
          to="/blog"
          >Blog</router-link
        >-->
            </links-container>
            <svg-container />
        </div>
        <div class="service">
            <span class="live-service">24/7 LIVE SERVICE</span>
            <a :href="'tel:+18886683009'" class="call-service">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.1218 5.00596C0.386249 3.52982 1.12841 1.90072 2.54263 1.05219C3.22351 0.64366 4.1035 0.820956 4.57306 1.46127L5.61484 2.88188C6.05825 3.48652 6.12621 4.28839 5.79088 4.95903L5.51773 5.50534C5.43866 5.66348 5.42616 5.84642 5.50704 6.00364C5.65441 6.29009 5.96822 6.79771 6.57275 7.40224C7.17728 8.00677 7.6849 8.32058 7.97135 8.46795C8.12857 8.54883 8.31151 8.53633 8.46966 8.45726L9.01596 8.18411C9.6866 7.84878 10.4885 7.91674 11.0931 8.36015L12.5137 9.40193C13.154 9.87149 13.3313 10.7515 12.9228 11.4324C12.0743 12.8466 10.4452 13.5887 8.96903 12.8532C7.72495 12.2333 6.13517 11.2073 4.45143 9.52356C2.76769 7.83982 1.74172 6.25005 1.1218 5.00596Z"
                        fill="#b4853b"
                    />
                </svg>
                <span class="tel"> +1 (888) 668-3009 </span>
            </a>
        </div>
    </div>
</template>

<script>
// Components
import LinksContainer from '@/common/LinkContainer.vue'
import SvgContainer from './SvgContainer.vue'
import DialogForm from '@/components/DialogForm.vue'
export default {
    components: {
        LinksContainer,
        SvgContainer,
        DialogForm,
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        burgherMenu() {
            this.$store.commit('displayBurgherMenu')
        },
        scrollTo() {
            if (window.location.pathname === '/' || window.location.pathname === '/flight') {
                window.scrollTo({
                    top: document.querySelector('.about-container').offsetTop,
                    behavior: 'smooth',
                })
                return
            }

            this.$store.commit('setAboutUs', true)
            this.$router.push('/')
        },
    },
    mounted() {
        window.addEventListener('scroll', () => {
            if (!this.$refs.header) {
                return
            }

            if (document.documentElement.scrollTop > this.$refs.header.clientHeight) {
                this.$refs.header.classList.add('fixed')
                return
            }

            this.$refs.header.classList.remove('fixed')
        })
    },
}
</script>

<style lang="scss" src="@/styles/header.scss"></style>
<style scoped lang="scss">
.logo {
    &-svg {
        width: auto;
        height: 32px;
    }
}

@media screen and (max-width: 768px) {
    .service {
        display: none;
    }
    .logo {
        width: 100%;
        justify-content: space-between;

        .burgher {
            order: -1;
        }

        .trigger {
            order: 1;
        }
    }
}
.header-container.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: unset;
    padding: 20px 16px;
    background-color: #1a1d1f;
    animation: slide-down 250ms;

    .logo {
        margin-bottom: 0;
    }

    @media screen and (max-width: 768px) {
        .logos-items {
            display: none;
        }
    }

    @media screen and (min-width: 769px) {
        padding-inline: 30px;

        .service {
            right: 30px;
        }
    }

    @media screen and (min-width: 1201px) {
        padding-inline: 80px;
    }
}

@keyframes slide-down {
    from {
        translate: 0 -100%;
    }
    to {
        translate: 0 0;
    }
}
</style>
